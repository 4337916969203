import AsyncStorage from "@react-native-async-storage/async-storage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const initialStore = {
  accessToken: "",
  hasTokenExpired: false,
  isRefreshing: false,
  refreshToken: "",
};

export const useAuthTokenStore = create()(
  persist(
    (set) => ({
      ...initialStore,
      _hasHydrated: false,
      email: "",
      hasCheckedTokenValidity: false,
      hasTokenExpired: false,
      isRefreshing: false,
      reset: () => {
        set(initialStore);
      },
      setHasCheckedTokenValidity: (hasCheckedTokenValidity) =>
        set({ hasCheckedTokenValidity }),
      setHasHydrated: (hasHydrated) => {
        set({ _hasHydrated: hasHydrated });
      },
    }),
    {
      name: "auth-token-store",
      // unique name
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },

      storage: createJSONStorage(() => AsyncStorage),
    }
  )
);
