import routes, { getRoutes } from "./routes";
import { Route, Routes, Navigate } from "react-router-dom";
import Radium, { StyleRoot } from "radium";
import { QueryClient, QueryClientProvider } from "react-query";

export const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StyleRoot>
        <div>
          <Routes>
            {getRoutes(routes())}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </StyleRoot>
    </QueryClientProvider>
  );
}

export default Radium(App);
