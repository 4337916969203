import Radium from 'radium';
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import styles from '../styles/videos';
import Navbar from "../components/Navbar";
import FlatList from 'flatlist-react';


function Simulator() {
    const navigate = useNavigate();
    const people = [
        {firstName: 'Elson', lastName: 'Correia', info: {age: 24}},
        {firstName: 'John', lastName: 'Doe', info: {age: 18}},
        {firstName: 'Jane', lastName: 'Doe', info: {age: 34}},
        {firstName: 'Maria', lastName: 'Carvalho', info: {age: 22}},
        {firstName: 'Kelly', lastName: 'Correia', info:{age: 23}},
        {firstName: 'Don', lastName: 'Quichote', info: {age: 39}},
        {firstName: 'Marcus', lastName: 'Correia', info: {age: 0}},
        {firstName: 'Bruno', lastName: 'Gonzales', info: {age: 25}},
        {firstName: 'Alonzo', lastName: 'Correia', info: {age: 44}},
        {firstName: 'Elson', lastName: 'Correia', info: {age: 24}},
        {firstName: 'John', lastName: 'Doe', info: {age: 18}},
        {firstName: 'Jane', lastName: 'Doe', info: {age: 34}},
        {firstName: 'Maria', lastName: 'Carvalho', info: {age: 22}},
        {firstName: 'Kelly', lastName: 'Correia', info:{age: 23}},
        {firstName: 'Don', lastName: 'Quichote', info: {age: 39}},
        {firstName: 'Marcus', lastName: 'Correia', info: {age: 0}},
        {firstName: 'Bruno', lastName: 'Gonzales', info: {age: 25}},
        {firstName: 'Alonzo', lastName: 'Correia', info: {age: 44}},
        {firstName: 'Elson', lastName: 'Correia', info: {age: 24}},
        {firstName: 'John', lastName: 'Doe', info: {age: 18}},
        {firstName: 'Jane', lastName: 'Doe', info: {age: 34}},
        {firstName: 'Maria', lastName: 'Carvalho', info: {age: 22}},
        {firstName: 'Kelly', lastName: 'Correia', info:{age: 23}},
        {firstName: 'Don', lastName: 'Quichote', info: {age: 39}},
        {firstName: 'Marcus', lastName: 'Correia', info: {age: 0}},
        {firstName: 'Bruno', lastName: 'Gonzales', info: {age: 25}},
        {firstName: 'Alonzo', lastName: 'Correia', info: {age: 44}}
    ]

    const renderVideos = (video, idx) => {
        return (
            <>
                {/*{idx === "0" &&*/}
                {/*    <div>*/}
                {/*        <div style={{...styles.card, ...styles.firstCard}} key={idx}>*/}
                {/*            <img src="assets/icons/add-file.png" style={styles.iconFile} alt="file"/>*/}
                {/*            <div style={styles.textAddFile}>Ajoutez un nouveau document</div>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*}*/}
                <div>
                    <div style={styles.card} key={idx}>
                        <img src="assets/images/img-video.png" style={styles.imageVideo} alt="thumbnails"/>
                    </div>
                    <div style={styles.textItem}>{video.firstName}{video.lastName}.pdf</div>
                </div>

            </>
        );
    }

    return (

        <div id="profil">
            <Navbar/>
            <div style={styles.bg}>
                <div style={styles.container}>
                    <div style={styles.videos}>
                        <div style={styles.headerProfil}>
                            <div style={styles.h1}>Nos simulateurs</div>
                            <div style={styles.subtitle}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={styles.section}>
                <div style={styles.videosList}>
                    <FlatList
                        list={people}
                        renderItem={renderVideos}
                        renderWhenEmpty={() => <div>List is empty!</div>}
                    />
                </div>

            </div>
        </div>

    );
}

export default Radium(Simulator);
