import { useMutation } from "react-query";
import useApi from "../axios";
import { queryClient } from "../../../App";

export const useUpdateUser = (id) => {
  const { patch } = useApi();

  return useMutation({
    mutationFn: async (payload) => await patch(`/users/${id}`, payload),
    onSuccess: () => {
      queryClient.refetchQueries(["user"]);
    },
  });
};
