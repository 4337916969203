import { useMutation } from "react-query";
import useApi from "../axios";

export const useAddNotificationToken = () => {
  const { post } = useApi();

  return useMutation({
    mutationFn: async (payload) =>
      await post(`/notifications/add_notification_token`, payload),
  });
};
