import { useQuery } from "react-query";
import useApi from "../axios";

export const useVideos = () => {
  const { get } = useApi();

  return useQuery({
    queryFn: async () => {
      const response = await get("/videos");
      return response.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    },
    queryKey: ["videos"],
  });
};
