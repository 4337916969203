import { useMutation, useQuery } from "react-query";
import useApi from "../axios";
import { queryClient } from "../../../App";

export const useDocuments = () => {
  const { get } = useApi();

  return useQuery({
    queryFn: async () => await get("/documents"),
    queryKey: ["documents"],
  });
};

export const useDocumentsTypes = () => {
  const { get } = useApi();

  return useQuery({
    queryFn: async () => await get("/documents/types"),
    queryKey: ["documentsTypes"],
  });
};

export const useDeleteDocuments = () => {
  const { del } = useApi();

  return useMutation({
    mutationFn: async (id) => await del(`/documents/${id}`),
    onSuccess: () => {
      queryClient.refetchQueries(["documents"]);
    },
  });
};

export const useUploadDocument = () => {
  const { post } = useApi();

  return useMutation({
    mutationFn: async (payload) => await post(`/documents`, payload),
    onSuccess: () => {
      queryClient.refetchQueries(["documents"]);
    },
  });
};

export const useUpdateDocument = (id) => {
  const { patch } = useApi();

  return useMutation({
    mutationFn: async (type_id) => {
      console.log("PAYLOAD", type_id);
      console.log("ID", id);
      await patch(`/documents/${id}`, { document: type_id });
    },
    onSuccess: () => {
      queryClient.refetchQueries(["documents"]);
    },
  });
};
