
import React from "react";
import {Route} from "react-router-dom";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Profil from "./pages/profil";
import Videos from "./pages/videos";
import Simulator from "./pages/simulator";

export const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }

        if (route.route) {
            return <Route exact path={route.route} element={route.component} key={route.key} />;
        }

        return null;
    });

const routes = () => {
    let r = [

        {
            key: "login",
            route: "/login",
            component: <Login />,
        },
        {
            key: "signup",
            route: "/signup",
            component: <Signup />,
        },
        {
            key: "videos",
            route: "/videos",
            component: <Videos />,
        },
        {
            key: "profil",
            route: "/profil",
            component: <Profil />,
        },
        {
            key: "simulator",
            route: "/simulator",
            component: <Simulator />,
        },


    ];

    return r ;
}

export default routes;
