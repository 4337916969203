const styles = {
  body: {
    /*background-color: red;*/
    margin: 0,
    //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //sans-serif;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
  },

  code: {
    //font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    //monospace;
  },

  /* ============================================================================================= */
  /* profil */
  /* ============================================================================================= */
  bg: {
    width: "100%",
    height: "35vh",
    background: "rgb(2,0,36)",
    //background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,35,1) 41%, rgba(0,11,113,1) 100%);
  },

  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  profil: {
    marginTop: "40px",
    width: "35%",
    justifyContent: "center",
    alignItems: "center",
    /*background-color: red;*/
  },

  headerProfil: {
    width: "100%",
    /*background-color: #004EFF;*/
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  containerImageProfil: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    marginBottom: "20px",
    backgroundColor: "#636667",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  containerIconEdit: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 20,
    backgroundColor: "white",
    border: "1px solid #e5e5e5",
  },
  LogoutButton: {
    backgroundColor: "red",
    color: "white",
  },
  iconEdit: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
  },

  title: {
    color: "white",
    fontSize: "2rem",
    fontWeight: "800",
    marginBottom: "20px",
    textAlign: "center",
  },

  contentProfil: {
    width: "100%",
    backgroundColor: "white",
    border: "2px solid #e5e5e5",
    borderRadius: "7px",
    marginTop: "-60px",
    marginBottom: "40px",
  },

  bloc1: {
    padding: "100px 40px 40px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  bloc2: {
    padding: "10px 40px 10px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "2px solid #e5e5e5",
    borderBottom: "2px solid #e5e5e5",
  },

  bloc3: {
    padding: "20px 40px 20px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  /* ======================================== FLATLISIT ===================================================== */

  contentFlatlist: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    /*background-color: red;*/
  },
  card: {
    width: "140px",
    height: "140px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    borderRadius: "7px",
    border: "2px solid #e5e5e5",
    borderBottom: "1px solid #e5e5e5",
    marginBottom: "10px",
    marginTop: "20px",
    marginRight: "20px",
    position: "relative",
  },
  firstCard: {
    marginTop: "-5px",
  },
  iconFile: {
    width: "60px",
    height: "60px",
    objectFit: "contain",
    marginBottom: "20px",
  },
  textAddFile: {
    fontSize: "12px",
    fontWeight: 300,
    color: " #707070",
    textAlign: "center",
  },
  containerIconDelete: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "20",
    boderColor: "transparent",
    border: "none",
    background: "none",
    /*background-color: white;*/
  },
  iconDelete: {
    width: "15px",
    height: "15px",
    objectFit: "contain",
  },
  textItem: {
    fontSize: "14px",
  },
};
export default styles;
