import Radium from "radium";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/login.css";
import { useLogin } from "../services/api/auth/use-login";
import { useAuthTokenStore } from "../services/stores/token";
import { useAddNotificationToken } from "../services/api/notifications/use-notifications";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { mutateAsync: signIn, isSuccess } = useLogin();
  const { mutateAsync: addOneSignalId } = useAddNotificationToken();

  const onSubmit = async () => {
    try {
      const response = await signIn({ email, password });
      if (response) {
        useAuthTokenStore.setState({
          accessToken: response.access_token,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isSuccess) {
    addOneSignalId({ one_signal_id: "test1" });
    navigate("/profil");
  }

  return (
    <div className="bg-home" id="bg-home">
      <div id="container-white">
        <div className="subtitle">Bienvenue sur</div>
        <img
          src="assets/logo/logo.png"
          alt="Logo de Flit Finance"
          className="img-logo-home"
        />

        <div className="row">
          <div className="col-12">
            <div className="container-input">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Adresse mail"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="container-input">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                placeholder="Mot de passe"
              />
            </div>
          </div>
        </div>
        <div className="row" id="mdp">
          <div className="col-12 d-flex justify-content-end align-item-end">
            <div className="text">
              <a href="/" className="link-underline">
                Mot de passe oublié ?
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <input
              type="submit"
              value="Se connecter"
              onClick={onSubmit}
              //   onClick={() => navigate("/profil")}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <div className="text">
              Je n'ai pas de compte :{" "}
              <a href="/signup" className="link-blue">
                S'inscrire
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Radium(Login);
