import { useMutation } from "react-query";
import useApi from "../axios";

export const useSignUp = () => {
  const { post } = useApi();

  return useMutation({
    mutationFn: async (data) => await post("users/create", data),
  });
};
