const styles = {
  body: {
    margin: 0,
    //fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //sans-serif,
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
  },

  code: {
    //#fontFamily: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New',
    //monospace;
  },

  /* ============================================================================================= */
  /* VIDEOS */
  /* ============================================================================================= */
  videoWrapper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  bg: {
    width: "100%",
    height: "35vh",
    background: "rgb(2, 0, 36)",
    //background: 'linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 35, 1) 41%, rgba(0, 11, 113, 1) 100%)',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  container: {
    width: "35%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    /*background-color: red;*/
  },

  h1: {
    color: "white",
    fontSize: "4rem",
    fontWeight: "600",
    marginBottom: "20px",
    textAlign: "center",
  },

  subtitle: {
    color: "white",
    fontSize: "1.5rem",
    fontWeight: "300",
    marginBottom: "20px",
    textAlign: "center",
  },
  modal: {
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      transition: "opacity 0.2s ease-in-out",
      opacity: 1,
    },
    content: {
      width: "1000px",
      height: "700px",
      position: "relative",
      margin: "auto",
      padding: "0",
      border: "none",
      background: "none",
      borderRadius: "10px",
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
      transition: "opacity 0.2s ease-in-out",
      opacity: 1,
      transform: "scale(1)",
    },
  },

  section: {
    padding: "40px",
    // backgroundColor: '#004EFF',
  },

  videosList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "30px",
  },

  headerProfil: {
    width: "100%",
    // /*background-color: #004EFF;*/
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  /* ======================================== FLATLISIT ===================================================== */
  card: {
    width: "300px",
    height: "200px",
    objectFit: "cover",
    backgroundColor: "#fff",
    borderRadius: "7px",
  },
  button: {
    border: "none",
    backgroundColor: "transparent",
  },

  imageVideo: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    objectFit: "cover",
  },

  firstCard: {
    marginTop: "-5px",
    width: "300px",
    height: "200px",
    objectFit: "cover",
    backgroundColor: "#fff",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  iconFile: {
    width: "60px",
    height: "60px",
    objectFit: "contain",
    marginBottom: "20px",
  },
  textItem: {
    marginTop: "10px",
    fontWeight: "600",
    fontSize: 12,
  },
};

export default styles;
