import Radium from "radium";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/videos";
import FlatList from "flatlist-react";

import { useVideos } from "../services/api/videos/use-videos";
import Navbar from "../components/Navbar";
import Modal from "react-modal";

function Videos() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const { data: videos } = useVideos();
  Modal.setAppElement("#root");

  const renderVideos = (video, idx) => {
    return (
      <div key={idx}>
        <button
          onClick={() => {
            setCurrentVideo(video);
            setModalIsOpen(true);
          }}
          style={{ ...styles.card, ...styles.button }}
          key={idx}
        >
          <img
            src="assets/images/img-video.png"
            style={styles.imageVideo}
            alt="thumbnails"
          />
        </button>
        <div style={styles.textItem}>{video?.name}</div>
      </div>
    );
  };

  return (
    <div id="profil">
      <Navbar />
      <div style={styles.bg}>
        <div style={styles.container}>
          <div style={styles.videos}>
            <div style={styles.headerProfil}>
              <Modal
                appElement={document.getElementById("root")}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={styles.modal}
              >
                {currentVideo && (
                  <div style={styles.videoWrapper}>
                    <video style={styles.video} controls autoPlay={true}>
                      <source
                        src={`${currentVideo.video_url}`}
                        type="video/mp4"
                      ></source>
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </Modal>
              <h1>Nos vidéos</h1>
              <div style={styles.subtitle}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.section}>
        <div style={styles.videosList}>
          <FlatList
            list={videos}
            renderItem={renderVideos}
            renderWhenEmpty={() => <div>List is empty!</div>}
          />
        </div>
      </div>
    </div>
  );
}

export default Radium(Videos);
