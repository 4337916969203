import { useMutation } from "react-query";
import useApi from "../axios";

export const useLogin = () => {
  const { post } = useApi();

  return useMutation({
    mutationFn: async (data) => await post("/auth/login", data),
  });
};
