import Radium from "radium";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/login.css";
import { useSignUp } from "../services/api/auth/use-signup";

function Signup() {
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip_code, setZipcode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const { mutateAsync: signUp } = useSignUp();

  const onSubmit = async () => {
    if (password !== passwordConfirm) {
      alert("Les mots de passe ne correspondent pas");
      return;
    }
    try {
      const response = await signUp({
        user: {
          firstname,
          lastname,
          phone,
          address,
          city,
          zip_code,
          email,
          password,
        },
      });
      if (response) {
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-home" id="bg-home">
      <div id="container-white">
        <div className="subtitle">Bienvenue sur</div>
        <img
          src="assets/logo/logo.png"
          alt="Logo de Flit Finance"
          className="img-logo-home"
        />
        <div className="row">
          <div className="col-6">
            <div className="container-input">
              <input
                onChange={(e) => setFirstname(e.target.value)}
                type="text"
                placeholder="Prénom"
              />
            </div>
            <div className="container-input">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Adresse mail"
              />
            </div>
            <div className="container-input">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                placeholder="Mot de passe"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="container-input">
              <input
                onChange={(e) => setLastname(e.target.value)}
                type="text"
                placeholder="Nom"
              />
            </div>
            <div className="container-input">
              <input
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                placeholder="N° téléphone"
              />
            </div>
            <div className="container-input">
              <input
                onChange={(e) => setPasswordConfirm(e.target.value)}
                type="text"
                placeholder="Répétez mot de passe"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="container-input">
              <input
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                placeholder="Adresse postale"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="container-input">
              <input
                onChange={(e) => setCity(e.target.value)}
                type="text"
                placeholder="Ville/Commune"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="container-input">
              <input
                onChange={(e) => setZipcode(e.target.value)}
                type="text"
                placeholder="Code postal"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <input type="submit" value="S'inscrire" onClick={onSubmit} />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <div className="text">
              J'ai un compte : <a href="/login">Se connecter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Radium(Signup);
